import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {Controller, FieldErrors, FieldPath, FieldValues} from "react-hook-form";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {Box, TextField, Typography} from "@mui/material";
import moment from "moment";

interface Props {
  control: any;
  name: string;
  displayText: string;
  rules?: Omit<RegisterOptions<FieldValues, FieldPath<any>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  errors?: FieldErrors;
  type?: string;
  variant?: 'outlined' | 'standard';
  disabled?: boolean;
}

const DatePickerFormControl = (props: Props) => {
  return (
    <Box sx={{width: '100%', mx: {xs: '0', md: '1rem'}}}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue=""
        rules={props.rules}
        render={
          ({field: {onChange, ...restField}}) =>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={props.displayText}
                inputFormat='DD/MM/YYYY'
                onChange={(event) => {
                  console.log(event);

                  onChange(event);
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    sx={{width: '100%', my: '1rem'}}
                    error={!!props.errors?.[props.name]}
                    variant={props.variant || 'standard'}
                    disabled={props.disabled || false}
                  />}
                {...restField}
              />
            </LocalizationProvider>
        }
      />
      {
        props.errors?.[props.name] &&
          <Typography variant='caption'
                      sx={{color: 'red'}}>{props.errors?.[props.name]?.message?.toString()}</Typography>
      }
    </Box>
  )
}

export default DatePickerFormControl;