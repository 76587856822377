import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import * as api from "../../api/api-client";
import { toast } from "react-toastify";

const SettingsAnafPage = () => {
  const [status, setStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);

  const openAnafAuthWindow = (id: string) => {
    if (!id) {
      toast.error("ID-ul utilizatorului nu este valid!");
      return;
    }
    
    const url = `https://logincert.anaf.ro/auth?sessionId=${id}`; // Verifică URL-ul corect pentru autentificare
    window.open(url, "_blank", "width=800,height=600");
  };

  useEffect(() => {
    api
      .get("/users/current")
      .then((res) => {
        setCurrentUser(res);
        if (res.token_anaf) setStatus(true);
      })
      .catch((err) => {
        console.error("Eroare la încărcarea utilizatorului:", err);
        toast.error("Nu s-a putut încărca utilizatorul");
      });
  }, []);

  return (
    <Container
      sx={{
        height: "100%",
        margin: 0,
        marginTop: "3rem",
        maxWidth: "100vw !important",
      }}
    >
      <Typography variant="h4">Setări ANAF</Typography>
      <Box sx={{ my: 4, width: "50%" }}>
        <TextField
          sx={{ input: { color: status ? "green" : "red" } }}
          name="status"
          variant="standard"
          aria-readonly={true}
          helperText="Status token generat"
          value={status ? "Token activ" : "Token inactiv"}
        />
      </Box>
      <Box sx={{ my: 2, width: "75%" }}>
        <Button
          sx={{ my: 2, width: "50%" }}
          type="button"
          variant="contained"
          disabled={status}
          onClick={() => openAnafAuthWindow(currentUser?.user_id)}
        >
          Generare token
        </Button>
      </Box>
    </Container>
  );
};

export default SettingsAnafPage;