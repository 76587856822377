import {FieldValues, useForm} from "react-hook-form";
import {Box, Button, Tooltip} from "@mui/material";
import * as api from "../../api/api-client";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Add} from "@mui/icons-material";
import CreatePartnerForm from "./CreatePartnerForm";
import DialogContainer from "../DialogContainer";
import DatePickerFormControl from "../form-helpers/DatePickerFormControl";
import AutocompleteFormControl from "../form-helpers/AutocompleteFormControl";
import SelectFormControl from "../form-helpers/SelectFormControl";
import BasicFormControl from "../form-helpers/BasicFormControl";
import Table from "../table/Table";
import CreateOrderDetailsForm from "./CreateOrderDetailsForm";
import {v4} from "uuid";

interface Props {
  onOrderSubmit: (order: any) => void;
}

interface PartnerAutocompleteModel {
  partner_id: number;
  partner_name: string;
}

const CreateOrderForm = (props: Props) => {
  const [addOrderDetailsDialogOpen, setAddOrderDetailsDialogOpen] = useState<boolean>(false);
  const [addPartnerDialogOpen, setAddPartnerDialogOpen] = useState<boolean>(false);

  const [userPartner, setUserPartner] = useState<{ user_id: number, partner_id: number }>({} as any);
  const [partners, setPartners] = useState<PartnerAutocompleteModel[]>([]);
  const [transporters, setTransporters] = useState<PartnerAutocompleteModel[]>([]);

  const [series, setSeries] = useState<any[]>([{label: 'Selecteaza o serie', value: 'no'}]);

  const [orderDetails, setOrderDetails] = useState<any[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<any>({});

  const tableHeaders = [
    {
      id: 'type',
      label: 'Tip',
      width: 100
    },
    {
      id: 'location',
      label: 'Locatie',
      width: 100
    },
    {
      id: 'date_from',
      label: 'De la',
      type: 'date',
      width: 100
    },
    {
      id: 'date_to',
      label: 'La',
      type: 'date',
      width: 100
    },
    {
      id: 'remarks',
      label: 'Note',
      width: 100
    }
  ]

  const actions = [
    {
      name: 'deleteRow',
      displayName: 'Sterge randul',
      action: handleOrderDetailsDelete
    },
    {
      name: 'editRow',
      displayName: 'Modifica randul',
      action: handleOrderDetailsEdit
    }
  ]

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
    watch,
    reset
  } = useForm({
    defaultValues: {
      client: '',
      transporter: '',
      buyer: '',
      series: '',
      number: '',
      driver_info: '',
      car_reg_number: '',
      package_info: '',
      remarks_transporter: '',
      remarks_buyer: '',
      client_price: '',
      client_currency: 'RON',
      transporter_price: '',
      transporter_currency: 'RON',
      user_id: '',
      created_at_utc: '',
      client_contact: '',
      transporter_contact: '',
      transporter_vat: 19,
      client_vat: 19
    }
  });

  const watchSeries = watch('series');

  useEffect(() => {
    api.get('/user/partner').then((res) => {
      setUserPartner(res);
    }).catch((err) => {
      toast.error(err);
    })

    api.get('/users/series').then((res) => {
      const orderSeries = res.filter((resItem: any) => resItem.invoice_type === 'order');
      setSeries(orderSeries.map((orderSerie: any) => {
        return {label: orderSerie.series, value: orderSerie.series}
      }));
    }).catch((err) => {
      toast.error(err);
    })
  }, []);

  useEffect(() => {
    if (watchSeries) {
      api.post("/orders/series", {series: watchSeries, client_id: userPartner.partner_id}).then((res: any) => {
        setValue('number', res.number ? res.number : 1);
      }).catch((err) => {
        console.error(err)
      });
    }
  }, [watchSeries])

  const onBuyerAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setPartners(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const onTransporterAutocompleteInput = (event: any) => {
    const {value} = event.target;

    if (value.length >= 3) {
      api.get(`/partners/autocomplete?searchKey=${value}`).then((res) => {
        setTransporters(res);
      }).catch((err) => {
          toast(err, {type: 'error'});
          console.error(err);
        }
      )
    }
  }

  const openAddOrderDetailsDialog = () => {
    setAddOrderDetailsDialogOpen(true);
  }

  const onTransporterAutocompleteChange = (event: any, value: any) => {
    setValue('transporter', value.partner_id);
  }

  const onBuyerAutocompleteChange = (event: any, value: any) => {
    setValue('buyer', value.partner_id);
  }

  const onOrderSubmit = (data: FieldValues) => {

    const order = {
      ...data,
      client_id: userPartner.partner_id,
      buyer_id: data.buyer,
      transporter_id: data.transporter,
      order_details: orderDetails.map((orderDetailsItem) => {
        delete orderDetailsItem.key;
        return orderDetailsItem;
      })
    }

    console.log(order);

    props.onOrderSubmit(order);
  }

  const handleOrderDetailsSubmit = (data: FieldValues) => {
    if (data.key) {
      setOrderDetails((prevState) => prevState.map((item) => {
        if (item.key === data.key) {
          item = data;

          return item;
        } else {
          return item;
        }
      }));

      handleOrderDetailsDialogState(false);
      setSelectedOrder(undefined);
    } else {
      data.key = v4();
      setOrderDetails((prevState: any) => [...prevState, data]);
      handleOrderDetailsDialogState(false);
    }
  }

  function handleOrderDetailsDelete(row: any) {
    setOrderDetails((prevState) => prevState.filter((prevItem) => prevItem.key !== row.key))
  }

  function handleOrderDetailsEdit(row: any) {
    setSelectedOrder(row);
    setAddOrderDetailsDialogOpen(true);
  }

  const handleAddPartner = (data: FieldValues) => {
    api.post('/partners', data).then((res) => {
      toast.success('Partner successfully added');
    }).catch((err) => {
      toast.error(err?.message);
    });
  }

  const handleAddPartnerDialogState = (dialogState: boolean) => {
    setAddPartnerDialogOpen(dialogState);
  }

  const handleOrderDetailsDialogState = (dialogState: boolean) => {
    if (!dialogState) {
      setSelectedOrder(undefined);
    }

    setAddOrderDetailsDialogOpen(dialogState);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onOrderSubmit)}>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <SelectFormControl control={control}
                             name='series'
                             selectOptions={series}
                             displayText='Serie'
                             rules={{required: {value: true, message: 'Seria este obligatorie'}}}
          />

          <BasicFormControl control={control} name='number' displayText='Numar' disabled={true}/>

          <DatePickerFormControl control={control}
                                 name='created_at_utc'
                                 displayText='Data creare'
                                 rules={{
                                   required: {value: true, message: 'Data creare este obligatorie.'}
                                 }}
                                 errors={errors}/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>

          <AutocompleteFormControl control={control}
                                   name='transporter'
                                   displayText='Transportator'
                                   options={transporters}
                                   optionLabelFunc={(transporters) => transporters?.partner_name || ""}
                                   onInputHandler={onTransporterAutocompleteInput}
                                   onChangeHandler={onTransporterAutocompleteChange}
                                   rules={{
                                     required: {value: true, message: 'Transportatorul este obligatoriu.'}
                                   }}
                                   errors={errors}/>
          <Tooltip title="Create new client">
            <Button onClick={() => setAddPartnerDialogOpen(true)}>
              <Add/>
            </Button>
          </Tooltip>
        </Box>


        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <AutocompleteFormControl control={control}
                                   name='buyer'
                                   displayText='Beneficiar'
                                   options={partners}
                                   optionLabelFunc={(partners) => partners?.partner_name || ""}
                                   onInputHandler={onBuyerAutocompleteInput}
                                   onChangeHandler={onBuyerAutocompleteChange}
                                   rules={{
                                     required: {value: true, message: 'Beneficiarul este obligatoriu.'}
                                   }}
                                   errors={errors}/>

          <Tooltip title="Create new partner">
            <Button onClick={() => setAddPartnerDialogOpen(true)}>
              <Add/>
            </Button>
          </Tooltip>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='driver_info' displayText='Informatii sofer'/>

          <BasicFormControl control={control} name='car_reg_number' displayText='Numar de inmatriculare'/>

          <BasicFormControl control={control} name='package_info' displayText='Detalii marfa'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='client_price'
                            displayText='Pret client'
                            type='number'
                            rules={{required: {value: true, message: 'Pretul client este obligatoriu'}}}
                            errors={errors}/>

          <SelectFormControl control={control} name='client_currency'
                             selectOptions={[{label: 'EURO', value: 'EUR'}, {label: 'RON', value: 'RON'}]}
                             displayText='Valuta'
                             rules={{required: {value: true, message: 'Valuta este obligatorie'}}}
                             errors={errors}/>

          <BasicFormControl control={control} name='client_vat' displayText='TVA Client (%)' type='number'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='transporter_price'
                            displayText='Pret transportator'
                            type='number'
                            rules={{required: {value: true, message: 'Pretul transportator este obligatoriu'}}}
                            errors={errors}/>

          <SelectFormControl control={control} name='transporter_currency'
                             selectOptions={[{label: 'EURO', value: 'EUR'}, {label: 'RON', value: 'RON'}]}
                             displayText='Valuta'
                             rules={{required: {value: true, message: 'Valuta este obligatorie'}}}
                             errors={errors}/>

          <BasicFormControl control={control} name='transporter_vat' displayText='TVA transportator (%)' type='number'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='remarks_buyer' displayText='Observatii client'/>
          <BasicFormControl control={control} name='remarks_transporter' displayText='Observatii transportator'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
          <BasicFormControl control={control} name='transporter_contact' displayText='Contact transportator'/>

          <BasicFormControl control={control} name='client_contact' displayText='Contact client'/>
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <Table headers={tableHeaders} rows={orderDetails}
                 handleAddActionClick={() => setAddOrderDetailsDialogOpen(true)}
                 actions={actions}
          />
        </Box>

        <Button sx={{my: 2, width: '100%'}} type="submit" variant="contained">Submit</Button>
      </form>

      <DialogContainer isDialogOpen={addOrderDetailsDialogOpen} dialogTitle='Adauga o pozitie'
                       handleDialogState={handleOrderDetailsDialogState}>
        <CreateOrderDetailsForm handleOrderDetailsSubmit={handleOrderDetailsSubmit} existingOrder={selectedOrder}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={addPartnerDialogOpen} dialogTitle='Adauga un partener'
                       handleDialogState={handleAddPartnerDialogState}>
        <CreatePartnerForm onPartnerInput={handleAddPartner}/>
      </DialogContainer>

    </>
  )
}

export default CreateOrderForm;