import React, {useEffect, useState,} from "react";
import Table from "../../components/table/Table";
import {Container} from "@mui/material";
import DialogContainer from "../../components/DialogContainer";
import CreateOrderForm from "../../components/forms/CreateOrderForm";
import {FieldValues} from "react-hook-form";
import * as api from "../../api/api-client";
import {toast} from "react-toastify";
import {Link, useLocation, useNavigate} from "react-router-dom";
import PdfTemplate from "../../components/pdf-templates/PdfTemplate";
import TmsTransportTemplate from "../../components/pdf-templates/TmsTransportTemplate";
import TmsClientTemplate from "../../components/pdf-templates/TmsClientTemplate";
import {OrderTableHeaders} from "./OrdersTableConstants";
import {KeyValuePair} from "../../types/CommonTypes";
import {FilterSettings} from "../../types/FilterTypes";
import Filters from "../../components/filters/Filters";
import OrderReports from "../../components/order/OrderReports";
import {Roles} from "../../common/enums/roles";
import jwtDecode from "jwt-decode";


//const [user, setUser] = useState<any>();

const OrdersPage = () => {
  const [orders, setOrders] = useState<any>([]);
  const [createOrderDialogOpen, setCreateOrderDialogOpen] = useState<boolean>(false);

  const [transportPdfInvoice, setTransportPdfInvoice] = useState<any>();
  const [clientPdfDialogOpen, setClientPdfDialogOpen] = useState<boolean>(false);
  const [transportPdfDialogOpen, setTransportPdfDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<KeyValuePair>({} as KeyValuePair);
  const [queryParams, setQueryParams] = useState<string>('');
  const [rate, setRate] = useState<number>(0);
  const [reset, setReset] = useState<number>(0);

  const user = jwtDecode(localStorage.getItem('token') || '');

  const actions = [
    {
      name: 'handleTransportPDF',
      displayName: 'Comanda transportator',
      action: handleTransportPDF
    },
    {
      name: 'handleClientPDF',
      displayName: 'Comanda client',
      action: handleClientPDF
    },

    {
      name: 'handleCreateInvoiceFromOrder',
      displayName: 'Genereaza factura',
      action: handleCreateInvoiceFromOrder
    },
    {
      name: 'handleDeleteOrder',
      displayName: 'Sterge comanda',
      action: handleDeleteOrder
    },
    {
      name: 'handleCloneOrder',
      displayName: 'Cloneaza comanda',
      action: handleCloneOrder
    }
  ]

  const filterable: FilterSettings[] = [
    {
      key: 'series',
      display: 'Serie',
      type: 'text',
    },
    {
      key: 'number',
      display: 'Numar',
      type: 'number'
    },
    {
      key: 'buyer',
      display: 'Client',
      type: 'text'
    },
    {
      key: 'transporter',
      display: 'Transportator',
      type: 'text'
    },
    {
      key: 'created_from',
      display: 'Data emitere (de la)',
      type: 'date'
    },
    {
      key: 'created_to',
      display: 'Data emitere (pana la)',
      type: 'date'
    }
  ]

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (queryParams !== location.search) {
      setQueryParams(location.search);
    }
  }, [location])


  useEffect(() => {
    api.get(`/orders${queryParams}`).then((res) => {
      res.forEach((resItem: any) => {
        resItem.buyer = resItem?.buyer?.name;
        resItem.transporter = resItem?.transporter?.name;

        const first_pick_up_address = resItem?.OrderDetails?.sort((a: any, b: any) => a.date_from < b.date_from).find((order: any) => order.type === 'PICKUP');
        const last_drop_off_address = resItem?.OrderDetails?.sort((a: any, b: any) => b.date_from > a.date_from).find((order: any) => order.type === 'DROPOFF');

        resItem.pickup = first_pick_up_address?.date_from || undefined;
        resItem.pickup_address = first_pick_up_address?.location || '-';

        resItem.dropoff = last_drop_off_address?.date_from || undefined;
        resItem.dropoff_address = last_drop_off_address?.location || '-';

        const rate = Number(JSON.parse(localStorage.getItem('eurRate')!)?.rate);
        setRate(rate);
      });

      setOrders(res);
    }).catch((err) => {
      toast.error(err);
    })
  }, [queryParams, reset]);

  useEffect(() => {
    if (filters) {
      navigate(`?${new URLSearchParams(filters).toString()}`);
    }
  }, [filters])

  const handleCreateOrderDialogState = (dialogState: boolean) => {
    setCreateOrderDialogOpen(dialogState);
  }

  const handleTransportPdfDialogState = (dialogState: boolean) => {
    setTransportPdfDialogOpen(dialogState);
  }

  const handleClientPdfDialogState = (dialogState: boolean) => {
    setClientPdfDialogOpen(dialogState);

  }

  const handleOrderSubmit = (data: FieldValues) => {
    data.rate = rate;

    api.post('/orders', data).then((res) => {
      toast.success('Comanda a fost creata');
      handleCreateOrderDialogState(false);

      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  const formatCell = (row: any, value: string) => {
    return <Link to={`/orders/${row.order_id}`}>{value}</Link>
  }

  function handleTransportPDF(row: any) {
    api.get(`/orders/${row.order_id}`).then((res) => {
      setTransportPdfInvoice(res);
      setTransportPdfDialogOpen(true)
    }).catch((err) => {
      toast.error(err);
    })
  }

  function handleClientPDF(row: any) {
    api.get(`/orders/${row.order_id}`).then((res) => {
      setTransportPdfInvoice(res);
      setClientPdfDialogOpen(true)
    }).catch((err) => {
      toast.error(err);
    })
  }

  function handleDeleteOrder(row: any) {
    api.remove(`/orders/${row.order_id}`).then((res) => {
      toast.success(res.message);

      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err.message);
    })
  }

  function handleCreateInvoiceFromOrder(row: any) {
    if (user && (user as any)?.role === Roles.Administrator) {
      api.post('/orders/generate', row).then((res) => {
        toast.success(res.message);
        setReset(reset + 1);
      }).catch((err) => {
        toast.error(err.message);
      });
    } else {
      // Utilizatorul nu are permisiunea, afișează o eroare
      toast.error('Acces interzis. Nu aveți permisiunea de a efectua această acțiune.');
    }
  }

  function handleCloneOrder(row: any) {
    api.post('/orders/clone', {order_id: row.order_id}).then((res) => {
      toast.success(res.message);

      setReset(reset + 1);
    }).catch((err) => {
      toast.error(err);
    })
  }

  const onFilterSubmit = (filters: KeyValuePair) => {
    setFilters(filters);
  }

  return (
    <Container sx={{height: '100%', margin: 0, marginTop: '3rem', maxWidth: '100vw !important'}}>
      <OrderReports content={orders}/>

      <Filters filters={filters} filtersSettings={filterable} onFiltersSubmit={onFilterSubmit}/>

      <Table headers={OrderTableHeaders}
             rows={orders} handleAddActionClick={() => setCreateOrderDialogOpen(true)}
             getFormattedCell={formatCell} actions={actions}/>

      <DialogContainer isDialogOpen={createOrderDialogOpen} handleDialogState={handleCreateOrderDialogState}
                       dialogTitle='Adauga comanda' textSize='h2'>
        <CreateOrderForm onOrderSubmit={handleOrderSubmit}/>
      </DialogContainer>

      <DialogContainer isDialogOpen={transportPdfDialogOpen} dialogTitle='Comanda transportator'
                       handleDialogState={handleTransportPdfDialogState}>
        <PdfTemplate invoiceData={transportPdfInvoice}
                     exportName={`Contract_${transportPdfInvoice?.series}-${transportPdfInvoice?.number}`}
                     handleDialogOpenState={handleTransportPdfDialogState}>
          <TmsTransportTemplate invoiceDetails={transportPdfInvoice}/>
        </PdfTemplate>
      </DialogContainer>

      <DialogContainer isDialogOpen={clientPdfDialogOpen} dialogTitle='Comanda client'
                       handleDialogState={handleClientPdfDialogState}>

        <PdfTemplate invoiceData={transportPdfInvoice} handleDialogOpenState={handleClientPdfDialogState}
                     exportName={`Confirmare_contract_${transportPdfInvoice?.series}-${transportPdfInvoice?.number}`}>
          <TmsClientTemplate invoiceDetails={transportPdfInvoice}/>
        </PdfTemplate>


      </DialogContainer>

    </Container>
  )
}

export default OrdersPage;